<template>
  <div>
    <hero-page :illustration="pageIllustration()">
      <template #title>
        <VueMarkdown class="font-weight-bold text-uppercase text-h4 white--text">{{ content.page_title }}</VueMarkdown>
      </template>
      <template #under-title>
        <div ref="#contactUsButton">
          <v-btn rounded color="teal accent-4" :x-large="$vuetify.breakpoint.mdAndUp"
              class="my-md-16 mt-3 py-md-5 py-2 px-md-10 font-weight-bold" to="/contact-us"
          >
            Get
            In
            Touch
          </v-btn>
        </div>
      </template>
    </hero-page>
    <v-container fluid class="pa-0 overflow-x-hidden overflow-y-hidden">
      <VueMarkdown class="intro-text text-center flavor-text aboutText text-lg-body-1">
        {{ content.intro_message }}
      </VueMarkdown>
      <section>
        <h2  :class="$vuetify.breakpoint.mdAndDown ? 'text-center my-8 title-text' : $vuetify.breakpoint.lg ? 'text-center my-10 title-text' : $vuetify.breakpoint.xl ? 'text-center my-12 pb-12 title-text' : null">
          {{ sectionTitle(0) }}
        </h2>
        <v-row style="width: 100%;" class="justify-center justify-md-end" no-gutters>
          <v-col cols="10" md="6" class="d-flex">
            <VueMarkdown class="aboutText text-center text-md-right spaced-paragraph">
              {{ sectionDescription(0) }}
            </VueMarkdown>
          </v-col>
          <v-col cols="12" md="5" class="d-flex justify-end">
            <img v-if="content.sections[0].media" :src="content.sections[0].media.url"
                 alt="Development services illustration" class="laptop-img"/>
          </v-col>
        </v-row>
      </section>
      <triangle-on-sides>
        <div class="text-center my-14 title-text text-sm-h4 text-lg-h4" :style="{'--content': content.page_slogan}">
          First in class, because we put our clients first.
        </div>
      </triangle-on-sides>
      <section class="our-values px-10">
        <h2 class="my-16 text-center title-text">
          {{ sectionTitle(1) }}
        </h2>
        <v-row justify="space-around  " align="start" v-if="(content.our_values || []).length">
          <v-col cols="12" sm="3" v-for="item in content.our_values" :key="item.id">
            <div class="text-center">
              <img :src="getMediaUrl(item)" :alt="item.title" class="mx-auto" height="100"/>
              <p :class="`mt-5 text-h5 ${item.title === 'Customer Obsession' && 'coreValuesTitle'}`">{{ item.title }}</p>
              <div>
                {{ item.description }}
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
      <section class="who-we-are px-5 py-2">
        <v-row class="white--text who-we-are-row" justify="space-around">
          <v-col class="who-we-are-col" cols="6" md="4">
            <h2 class="title-text mb-2 mb-md-auto who-we-are-title">
              {{ sectionTitle(2) }}
            </h2>

            <VueMarkdown class="intro-text who-we-are-text">
              {{ sectionDescription(2) }}
            </VueMarkdown>
          </v-col>
          <v-col cols="6" md="4" class="text-right who-we-are-col">
            <h2 class="text-right title-text mb-2 mb-md-auto who-we-are-title-2">
              {{ sectionTitle(3) }}
            </h2>
            <VueMarkdown class="intro-text text-right who-we-are-text-2">
              {{ sectionDescription(3) }}
            </VueMarkdown>
          </v-col>
        </v-row>
      </section>
      <section class="my-16 overflow-x-hidden">
        <h2 class="mt-16 mb-5 text-center text-h2 font-weight-bold">
          {{ sectionTitle(5) }}
        </h2>
        <div v-for="item, index in content.focus_items" :key="item.id">
          <focus-card :index="index" :item="item"/>
        </div>
      </section>
      <divider-multicolor class="full-width my-16"/>
      <section class="global-team-section">
        <v-row align="center" justify="center" class="flex-column-reverse flex-md-row py-md-10 px-md-10">
          <v-col class="content" cols="12" sm="12" md="4">
            <h2 class="title-text mx-5 global-team">
              {{ sectionTitle(7) }}
            </h2>
            <p class="my-6 mx-5 intro-text text-justify text-md-left">
              {{ sectionDescription(7) }}
            </p>
          </v-col>
          <v-col cols="12" md="8">
            <div class="map">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-1 payton/wisconsin"/>
                </template>
                <span>Wisconsin</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-2 cody/oregon"/>
                </template>
                <span>Oregon</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-3 hunter/northCarolina"/>
                </template>
                <span>North Carolina</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-ceo jacobAndMax/nevada"/>
                </template>
                <span>Nevada</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-4 rafael/elSalvador"/>
                </template>
                <span>El Salvador</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-5 franciscoAndJuan/argentina"/>
                </template>
                <span>Argentina</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-6 paraguay"/>
                </template>
                <span>Paraguay</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-7 venezuela"/>
                </template>
                <span>Venezuela</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-8 kirill/kazakhstan"/>
                </template>
                <span>Russia</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-9 mike/california"/>
                </template>
                <span>California</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-10 anna/hungary"/>
                </template>
                <span>Hungary</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-12 nathaniel/chicago"/>
                </template>
                <span>Illinois</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-11 paulina/idaho"/>
                </template>
                <span>Idaho</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs"
                          v-on="on" class="marker-company mp/delaware"/>
                </template>
                <span>Delaware</span>
              </v-tooltip>
              <img src="/img/full-map-colorful.webp" alt="map"/>
            </div>
          </v-col>
        </v-row>
      </section>
    </v-container>
    <ScrollToTopButton/>
  </div>
</template>

<script>
import HeroPage from "@/components/ui/HeroPage.vue";
import TriangleOnSides from "@/components/ui/TriangleOnSides.vue";
import VueMarkdown from "vue-markdown";
import {mapActions, mapGetters} from "vuex";
import FocusCard from "@/components/pages/about/FocusCard";
import DividerMulticolor from "@/components/ui/DividerMulticolor";
import ScrollToTopButton from "@/components/ui/ScrollToTopButton";

export default {
  components: {
    ScrollToTopButton,
    DividerMulticolor,
    TriangleOnSides,
    HeroPage,
    VueMarkdown,
    FocusCard
  },
  name: "AboutPage",
  data() {
    return {
      message: "Welcome to Your Vue.js App",
    };
  },
  computed: {
    ...mapGetters("aboutPageStore", {
      content: "aboutPageContent",
    }),
    ...mapGetters("teammateStore", {
      activeTeammatesList: "getActiveTeammates",
    }),
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
    orderedTeammates() {
      return this.activeTeammatesList.slice().sort((a, b) => a.oid - b.oid)
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions("aboutPageStore", ["getContent"]),
    ...mapActions("teammateStore", ["getTeammates"]),
    initData() {
      this.getContent();
      this.getTeammates();
    },
    sectionTitle(index = 0) {
      return this.content?.sections[index]?.title || "";
    },
    sectionDescription(index = 0) {
      return this.content?.sections[index]?.description || "";
    },
    pageIllustration() {
      return `https://mathisonprojectsinc-media-storage.s3.amazonaws.com/Ilustracion_6_1_0c040ba777.png`
    },
    getMediaUrl(item) {
      return this.$getMediaURL(item?.media?.url)
    }
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 2000px) {
  .laptop-img {
    max-width: 100% !important;
    max-height: 65vh !important;
    margin-top: -9vh;
  }
  .who-we-are-title {
    font-size: 1.5rem;
  }
  .who-we-are-title-2 {
    font-size: 1.5rem;
    text-align: right;
  }
  .who-we-are-text {
    font-size: .8rem !important;
  }
  .who-we-are {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .global-team-section {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
  .who-we-are-row {
    width: 66%;
  }
  .who-we-are-text-2 {
    margin-left: auto;
  }
}
@media only screen and (min-width: 1040px) and (max-height: 1040px) {
  .who-we-are-row {
    padding: unset !important;
    margin: unset !important;
  }
  .who-we-are-col {
    min-width: 45% !important;
    padding: unset !important;
    margin: unset !important;
  }
}
@include screen('onlyMd') {
  .who-we-are {
    padding-left: 6%;
    padding-right: 6%;
  }
  .global-team {
    min-width: 75vw;
  }
}
.who-we-are {
  background-image: url(/img/whoweare.webp);
  background-position: center;
  background-size: 100%;
  min-height: 45vh !important;
  margin-top: 3rem;
  display: flex;
  align-items: center;
    @media (max-width: 320px) {
        background-size: 280%;
  }
}

.laptop-img {
  max-width: 100%;
  max-height: 50vh;
}

.section-heading {
  margin: 0 auto;
  font-size: 2rem;
  max-width: 50vw !important;

  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }
  display: flex;
  text-align: center;
  align-items: center;
}

h2 {
  margin: 0 auto;
  font-size: 2.5rem;
  max-width: 60vw !important;

  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  &:first-child {
    margin-top: 0;
  }
}

.aboutText {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: normal;
  @media (max-width: 620px) {
    padding: 1.3rem;
    font-size: 1rem;
  }
  @media only screen and (min-width: 2000px) {
    font-size: 1rem;
    line-height: 1.5;
  }
}

h1 {
  max-width: 16ch;
}

.custom-title {
  font-size: 2rem;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}

.intro-message {
  margin: 0 auto;
  max-width: 40rem;
  padding: 1.5rem 0;
  line-height: 1.2;
  font-size: 1.3rem;
}

.section-title {
  max-width: 25ch;
  display: inline-flex;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 768px) {
    max-width: 50ch;
    margin-bottom: 1rem;
  }
}

::v-deep .colorful-text {
  color: #fff;
  display: block;
  position: relative;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 3rem;
  cursor: pointer;

  &::before {
    content: "First in class because we put our clients first.";
    background: linear-gradient(
            90deg,
            rgba(248, 30, 140, 1) 0%,
            rgba(4, 223, 117, 1) 100%
    );
    position: absolute;
    inset: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media only screen and (max-width: 620px) {
    font-size: 2rem;
  }
}

.value-item {
  width: 100%;
  min-height: 20rem;
}

.coreValuesTitle {
  @media only screen and (min-width: 600px) and (max-width: 960px) {
    white-space: nowrap;
    text-indent: -25px;
  }
}

.origins-description {
  max-width: 55rem;
  margin: 0 auto;
}

.global-team-section {
  .description {
    max-width: 30rem;
    margin: auto;
  }

  h2 {
    max-width: 14ch !important;
    margin: auto;
  }

  .map {
    position: relative;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50px;
      background: #fff;
      z-index: 6;
      animation: highlight 3s linear infinite;

      &:hover {
        width: 12px;
        height: 12px;
        background: hotpink;
      }
    }

    @keyframes highlight {
      100% {
        transform: scale(3);
        opacity: 0;
      }
    }

    .marker-1 {
      top: 20%;
      left: 25%;
      animation-delay: 0.1s;
    }

    .marker-2 {
      top: 23%;
      left: 13%;
    }

    .marker-3 {
      top: 30%;
      left: 27%;
      animation-delay: 0.6s;
    }

    .marker-4 {
      top: 45%;
      left: 24%;
      animation-delay: 0.2s;
    }

    .marker-5 {
      bottom: 18%;
      left: 32%;
      animation-delay: 0.2s;
    }

    .marker-6 {
      bottom: 22%;
      left: 32%;
      animation-delay: 0.2s;
    }


    .marker-7 {
      bottom: 45%;
      left: 32%;
      animation-delay: .8s;
    }

    .marker-8 {
      top: 29%;
      right: 28%;
    }

    .marker-9 {
      top: 26%;
      left: 13%;
    }

    .marker-10 {
      top: 30%;
      right: 41%;
    }

    .marker-11 {
      top: 23%;
      left: 16%;
      animation-delay: 0.3s;
    }

    .marker-12 {
      top: 23%;
      left: 26%;
      animation-delay: 0.4s;
    }

    .marker-ceo {
      top: 28%;
      left: 16%;
    }

    .marker-company {
      top: 27%;
      left: 29%;
    }

    img {
      max-width: 100%;
      transform: scale(1);
    }
  }
}

.flip-card {
  max-width: 300px;

  .front-face {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      max-width: 100%;
    }
  }
}

.image {
  img {
    max-width: 100%;
    object-fit: contain;
  }
}
.header-primary {
  font-size: 2.5rem !important;
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    max-width: 60vw !important;
  }
}
.flavor-text {
  max-width: 65vw;
  margin-right: auto !important;
  margin-left: auto !important;
}
.about-container {
  padding-left: 12vw !important;
  padding-right: 12vw !important;
  @media only screen and (max-width: 500px) {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}
@include screen('onlySm') {
  .header-primary {
    font-size: 1.65em !important;
    margin-bottom: .66em;
  }
  .who-we-are {
    font-size: 1em !important;
    background-size: 175% !important;
    min-height: 40vh !important;
    .who-we-are-row {
      margin-top: 1em;
      max-height: 40vh !important;
    }
    .who-we-are-col {
      max-height: 55vh !important;
      h2 {
        padding-left: .5em;
        font-size: 1.5em;
      }
      .who-we-are-text {
        font-size: .8em !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
        margin-top: -1em;
      }
    }
  }
  .who-we-are-title-2 {
    padding-right: .5em;
  }
}
</style>
