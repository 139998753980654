<template>
  <div class="focus-card mb-10 d-flex align-center" :class="{'flex-row-reverse': item.isRight}">
    <div class="image pa-0 flex-shrink-1 flex-grow-0">
      <img :src="image" :alt="title" :style="imgSize()"/>
    </div>
    <div :class="[item.isRight ? `align-start aligned-right flex-grow-1 content d-flex flex-column card-${index}` :
     `text-right align-end aligned-left flex-grow-1 content d-flex flex-column card-${index}`]">
      <p :class="$vuetify.breakpoint.mobile ? `title-${index}` : ''" class="text-sm-h6 focus-text-title black--text font-weight-bold">{{title}}</p>
      <p :class="$vuetify.breakpoint.mobile ? `desc-${index}` : ''" class="text-body-1">{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FocusCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: Number
  },
  methods: {
    imgSize() {
      const {
        xs, sm, mdAndUp
      } = this.$vuetify.breakpoint;
      if(xs || sm) return 'height:258px;width:auto;'
      if(mdAndUp) return 'height:25vw;width:auto;'
    }
  },
  computed: {
    title() {
      return this.item.title || "";
    },
    description() {
      return this.item.description || "";
    },
    image() {
      return this.$getMediaURL(this.item.media?.url);
    },
  }
};
</script>

<style lang="scss" scoped>
.focus-card {
  width: 100%;
  overflow: hidden;
  .image {
    margin-left: -5vw;
    margin-right: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &.flex-row-reverse {
    .image {
      margin-left: 0;
      margin-right: -5vw;
    }
  }
  .content p {
    max-width: 50ch;
  }
}
/* right-aligned cards */
.card-0 {
  @include screen('md') {
    padding-right: 3%
  }
}
 .card-2 {
  @include screen('md') {
    padding-right: 3%
  }
}

/* left-aligned cards */
.card-1 {
  @include screen('md') {
    padding-left: 3%
  }
}
.card-3 {
  @include screen('md') {
    padding-left: 3%;
  }
}


@media screen and (max-width: 900px) {
    .focus-card {
      .content p {
        padding-right: 3ch;
        max-width: 100%;
      }
      .image {
        margin-right: 10vw;
      }
      &.flex-row-reverse {
        .image {
          margin-left: 0;
          padding-right: 6ch;
        }
        .content {
          padding-right: 0;
          padding-left: 3ch;
        }
      }
    }
  }

  @media only screen and (min-width: 2000px) {
    .image {
      width: 50vw !important;
      height: 50vh !important;
    }
    .text-body-2 {
      font-size: 1.25rem !important;
      line-height: 1.2em;
    }
    .focus-card {
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }
@media screen and (max-width: 500px) {
    .focus-card {
      .content p {
        max-width: 100%;
      }
      .image {
        margin-left: -25vmin;
        margin-right: 10vw; 
      }
      &.flex-row-reverse {
        .image {
          margin-left: 0;
          margin-right: -25vmin;
        }
        .content {
          padding-right: 0;
        }
      }
    }
    .card-2 {
        position: relative;
        right: 3%;
    }
      .desc-2 {
        position: relative;
        right: 15%;
        padding-left: 3%;
      }
    .card-3 {
        position: relative;
        left: 3%;
    }
      .desc-3 {
        position: relative;
        left: 6%;
        padding-right: 12% !important;
      }
      .title-3 {
        position: relative;
        left: 6%;
      }
  }
  @media only screen and (max-width: 375px) {
    .card-0 {
        position: relative;
        right: 6%;
    }
    .card-1 {
        position: relative;
        left: 6%;
    }
    .card-2 {
        position: relative;
        right: 15%;
    }
      .desc-2 {
        position: relative;
        right: 18%;
        padding-left: 24%;
      }
      .title-2 {
        position: relative;
        right: 3%;
      }
    .card-3 {
        position: relative;
        left: 18%;
    }
      .desc-3 {
        position: relative;
        left: 9%;
        padding-right: 33% !important;
      }
      .title-3 {
        position: relative;
        left: 9%;
      }
  }
 
 .focus-text-title {
   font-size: 30px !important;
 }

</style>
