<template>
  <div class="triangle-on-sides">
    <h2>
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {
  name: "TriangleOnSides",
};
</script>

<style lang="scss" scoped>
h2 {
  margin: 0 auto;
  font-size: 2rem;
  max-width: 50vw;

  @media only screen and (max-width: 620px) {
    font-size: 1.75rem;
    max-width: 70vw;
  }

  &:first-child {
    margin-top: 0;
  }
}
.triangle-on-sides {
  display: flex;
  text-align: center;
  align-items: center;
}
.triangle-on-sides:before {
  content: '';
  display: inline-flex;
  position: relative;
  width: 12em;
  height: 10em;
  background-image: url(/img/left-triangle.svg);
  background-size: contain;
}
.triangle-on-sides:after {
  content: '';
  display: inline-flex;
  position: relative;
  width: 12em;
  height: 10em;
  background-image: url(/img/right-triangle.svg);
  background-size: contain;
  margin-right: -7px;
}

.triangle-on-sides:after,.triangle-on-sides:before {
  @media only screen and (max-width: 850px) {
    width: 5em;
    height: 4em;
  }
  @media only screen and (max-width: 445px) {
    width: 4.5em;
    height: 5em;
  }
  @media only screen and (max-width: 425px) {
  width: 3.5em;
  height: 7.5em;
  margin-top: 3em;
  }
  @media only screen and (max-width: 375px) {
  width: 4em;
  height: 5em;
  margin-bottom: 2em;
  }
  @media only screen and (max-width: 320px) {
  width: 9em;
  height: 5em;
  }
}

@media (min-width: 768px) {
  h2 {
    font-size: 3rem;
  }
}
</style>
