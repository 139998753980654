<template>
  <div class="divider" />
</template>

<script>
  export default {
    name: "DividerMulticolor",
  }
</script>

<style lang="scss" scoped>
  .divider {
    background: url("/full-width-divider.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 2px;
    max-height: 2px;
  }

  .full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
</style>
